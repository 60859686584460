<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 23">
        <path
            d="M1.75195 19.9402c-.08125.1437-.12695.308-.12695.4774 0 .5237.41641.9447.93438.9447H23.4406c.518 0 .9344-.421.9344-.9447 0-.1694-.0457-.3285-.127-.4774L14.041 2.24866c-.2183-.37478-.6144-.6058-1.041-.6058s-.8227.23102-1.041.6058L1.75195 19.9402Zm-1.40156-.8317L10.5574 1.41696C11.0652.53906 11.9945 0 13 0s1.9348.53906 2.4426 1.41696l10.207 17.69154c.2285.3953.3504.8471.3504 1.304C26 21.8397 24.8523 23 23.4406 23H2.55938C1.14766 23 0 21.8397 0 20.4125c0-.4569.12187-.9087.3504-1.304ZM13 6.57143c.4469 0 .8125.36964.8125.82143v6.57144c0 .4518-.3656.8214-.8125.8214s-.8125-.3696-.8125-.8214V7.39286c0-.45179.3656-.82143.8125-.82143ZM11.7812 18.0714c0-.3268.1285-.6402.357-.8712.2286-.2311.5386-.3609.8618-.3609.3232 0 .6332.1298.8618.3609.2285.231.357.5444.357.8712s-.1285.6402-.357.8713c-.2286.2311-.5386.3609-.8618.3609-.3232 0-.6332-.1298-.8618-.3609-.2285-.2311-.357-.5445-.357-.8713Z"
            fill="currentColor"
        />
    </svg>
)
</script>

<style lang="scss" scoped>

</style>
